import React from "react";
import { useTranslation } from "react-i18next";

const Header = ({ onClick }: any) => {
  const {t} = useTranslation();

  return (
    <header style={{ position: "relative", overflow: "hidden" }}>
      <video
        autoPlay
        loop
        muted
        playsInline
        data-wf-ignore="true"
        data-object-fit="cover"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      >
        <source
          src="https://img.pikbest.com/10/10/78/35mpIkbEsT2Jz.mp4"
          type="video/mp4"
          data-wf-ignore="true"
        />
        Your browser does not support the video tag.
      </video>
      <div className="Header" style={{ height: '100vh', zIndex: 1, position: "relative", backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
        <img
          className="Token"
          src={require('../../assets/images/token.png')}
          alt="Token"
        />
        <div className="Header-text-wrapper">
          <span className="Header-title">{t('header.title')}</span>
          <p className="Header-text">
            {t('header.text')}
          </p>
          {/*<div className="Header-button-wrapper">
            <button className="Header-button" onClick={onClick}>{t('header.button')}</button>
          </div>*/}
        </div>
      </div>
    </header>
  );
};

export default Header;
